<template>
  <!--商城套餐管理-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="商品名称:">
                    <el-input
                      clearable
                      v-model="where.name"
                      placeholder="请输入商品名称"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="项目:">
                    <el-select
                      clearable
                      v-model="where.items_name"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="状态:">
                    <el-select
                      clearable
                      v-model="where.status"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.status_type"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="button" type="primary" @click="openEdit(null)"  v-auths="[`${$config.uniquePrefix}operate:mallPackageManagement:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">添加</span>
                </el-button>
              </div>
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <template v-slot:vip_day= "{ scope }">
                <div v-for="(item) in dict.type.validity">
                  <span v-if="scope.row.vip_day == item.value">{{item.label}}</span>
                </div>
              </template>

              <!--自定义列显示-->
              <template v-slot:status= "{ scope }">
                <div v-if="scope.row.status === 1">
                  <span style="width: 8px;height: 8px;background: #ff2525;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                  <span>已下架</span>
                </div>
                <div v-else>
                  <span style="width: 8px;height: 8px;background: #25EB61;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                  <span>已上架</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="openEdit(scope.row)"  v-auths="[`${$config.uniquePrefix}operate:mallPackageManagement:info`]">详情</el-link>
                <el-link :underline="false" type="warning" @click="openEdit(scope.row)"  v-auths="[`${$config.uniquePrefix}operate:mallPackageManagement:put`]">编辑</el-link>
                <el-link :underline="false" type="danger"  v-if="scope.row.status === 0" style="margin-right: 10px;" @click="EditStatus(scope.row,'1')"  v-auths="[`${$config.uniquePrefix}operate:mallPackageManagement:status`]">下架</el-link>
                <el-link :underline="false" type="danger"  v-if="scope.row.status === 1" style="margin-right: 10px;" @click="EditStatus(scope.row,'0')"  v-auths="[`${$config.uniquePrefix}operate:mallPackageManagement:status`]">上架</el-link>
                <!--<el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">-->
                <!--  <el-link slot="reference" :underline="false" type="info" style="margin-left: 10px;" v-auths="[`${$config.uniquePrefix}operate:mallPackageManagement:delete`]">删除</el-link>-->
                <!--</el-popconfirm>-->
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

    </div>

    <!--编辑商品弹窗-->
    <Edit v-if="showEdit === true" :data="current" :dict="dict" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
//服务项目接口
import {getget_services_available} from '@/api/yunli'
import {Delmall_package, getmall_package, getmall_packages, SaveStatus} from "@/api/operate";

//引入编辑商品弹窗组件
import Edit from './components/edit.vue'
import Vue from "vue";


export default {
  // 组件生效
  components: {
    PackageSortElement,
    Edit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "系统编号",
        //   // type: "index",
        //   // width: "70"
        //   prop: "id",
        //   isShow: true,
        //   fixed: 'left'
        // },
        {
          label: "商品名称",
          prop: "name",
          isShow: true
        },
        {
          label: "项目",
          prop: "items_name",
          isShow: true
        },
        {
          label: "次数",
          prop: "amount",
          isShow: true
        },
        {
          label: "金额",
          prop: "pay_price",
          isShow: true
        },
        {
          label: "有效期",
          prop: "vip_day",
          slot:'vip_day',
          isShow: true
        },
        {
          label: "状态",
          prop: "status",
          slot: 'status',
          isShow: true
        },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true
        },
        {
          label: "操作",
          width: "250",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //编辑商品
      current:null,
      showEdit:false,

      //字典
      dict: {
        type:{}
      },

      //服务项目
      ServicesAvailable:[],

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运营字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    //调用获取客户信息列表方法
    this.getList();
    //获取服务项目
    this.getFuwuxiangmu();
  },

  methods:{
    // 获取列表
    getList(){
      getmall_packages(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //点击删除
    del(row){
      Delmall_package(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    //状态修改
    EditStatus(row,str){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        // 参数
        let params = {
          status: str
        }
        SaveStatus(row.id,params).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg);
        })
      }, "确定", 'warning');
    },



    //编辑商品
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },



  }

}
</script>

<style lang="scss" scoped>

</style>
